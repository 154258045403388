<template>
  <div class="container-fluid bg-white pb-4" style="padding-top: 40px;">
    <div class="row my-auto">
      <div class="col-lg-6 mx-auto">
        <div class="tab-content">
          <img
            src="@/assets/img/auth/forgot1.png"
            class="img-responsive mx-auto d-block"
            style="padding-bottom: 10px;"
          />
          <form
            @submit.prevent="submitPassword"
            class="text-center text-mantra-dark"
          >
            <h3 class="text-center auth-forgot-1 mt-2 mb-2">
              Set new password
            </h3>

            <p class="auth-forgot-2">
              Your new password must be different to <br />
              previously used passwords.
            </p>

            <div class="mt-2 col-sm-8 mx-auto">
              <div class="form-group">
                <label for="new-pw" class="auth-forgot-3 float-left"
                  >New Password</label
                >
                <input
                  type="password"
                  class="form-control auth-border-clr pt-4 pb-4 pl-3"
                  id="new-pw"
                  placeholder="Masukkan password baru anda"
                  v-model="password"
                  required
                  @keyup="validateInput"
                />
                <small class="float-left auth-forgot-2 my-1"
                  >Must be at least 8 characters.</small
                >
              </div>
              <div class="form-group mt-5">
                <label for="conf-pw" class="auth-forgot-3 float-left"
                  >Confirm New Password</label
                >
                <input
                  type="password"
                  class="form-control auth-border-clr pt-4 pb-4 pl-3"
                  id="conf-pw"
                  placeholder="Konfirmasi password baru anda"
                  onpaste="return false;"
                  v-model="confirmPass"
                  required
                  @keyup="validateInput"
                />
              </div>
            </div>

            <div class="col-sm-8 mx-auto">
              <button
                id="submit-password"
                class="btn btn-primary w-100 outline-none mt-4 reset-btn"
                type="submit"
                disabled
              >
                Reset Password
              </button>
            </div>

            <div class="col-sm-8 mt-3 mx-auto">
              <router-link :to="{ name: 'login' }" class="auth-forgot-2">
                <i class="ri-arrow-left-line ri-mid mr-2 ri-lg"></i>
                Back to log in
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VALIDATE_OTP } from "../../graphql/mutations";
import { client } from "@/main";

export default {
  name: "reset-confirm",
  data() {
    return {
      email: this.$route.query.email,
      password: "",
      confirmPass: "",
      otp: this.$route.query.otp,
      ok: false,
      error: ""
    };
  },
  components: {},
  mounted() {},
  methods: {
    submitPassword() {
      if (this.ok) {
        return new Promise(async resolve => {
          try {
            const result = await client
              .mutate({
                mutation: VALIDATE_OTP,
                variables: {
                  email: this.email,
                  password: this.password,
                  otpCode: this.otp
                }
              })
              .catch(error => {
                alert("E " + error);
              });
            const { data } = result;
            resolve(data);
            // localStorage.removeItem("forgotEmail");
            this.$router.push({ name: "reset-success" });
          } catch (e) {
            resolve(e.message);
            alert(e.message);
          }
        });
      } else {
        alert("Not Valid!!");
      }
    },
    validateInput() {
      const pass = document.querySelector("#new-pw").value;
      const confPw = document.querySelector("#conf-pw").value;
      const validPass = /^.{8,64}$/;

      if (
        pass == confPw &&
        pass != "" &&
        confPw != "" &&
        validPass.test(pass)
      ) {
        document.querySelector("#submit-password").removeAttribute("disabled");
        document.querySelector("#submit-password").classList.remove("disabled");
        this.ok = true;
      } else {
        document
          .querySelector("#submit-password")
          .setAttribute("disabled", "disabled");
        document.querySelector("#submit-password").classList.add("disabled");
        this.ok = false;
      }
    }
  }
};
</script>

<style>
.auth-forgot-1 {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #000000;
}

.auth-forgot-2 {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #757575;
}

.auth-forgot-3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #757575;
}

.auth-help {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #757575;
}

.auth-forgot-2:hover {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #5c5c5c;
}

.reset-btn {
  background-color: #fd8833;
  color: #ffffff;
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 0;
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.reset-btn:disabled {
  background-color: #ffa869;
  color: #fff;
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  color: #c4c4c4;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  color: #c4c4c4;
}

::placeholder {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  color: #c4c4c4;
}
</style>
